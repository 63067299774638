.wrap {
  background: #fff;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}
.headerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333;
  font-size: 14px;
  margin: 0 auto;
  max-width: 1400px;
  width: 94%;
}

.langWrap {
  max-width: 1400px;
  width: 94%;
  color: #999;
  text-align: right;
  font-size: 10px;
  line-height: 16px;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: flex-end;
  margin-top: -30px;
  display: none;
}

.langWrap div {
  cursor: pointer;
  transition: all linear 200ms;
  margin-left: 8px;
}

.langWrap div.active {
  color: #ddd;
}

.langWrap div:hover {
  color: #eee;
}

.headerInner h1 {
  font-size: 32px;
  color: #fff;
}

.headerNavWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 120px;
  flex-grow: 1;
  font-weight: 500;
}

.headerNavWrap > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all linear 200ms;
  font-size: 18px;
  text-align: center;
  margin-left: 32px;
  font-weight: 500;
  color: #fff;
}
.dot {
  display: inline-block;
  width: 20px;
  height: 3px;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 1px;
  opacity: 0;
  transition: all linear 200ms;
}

.headerNavWrap > span.active .dot {
  opacity: 1;
}

.headerNavWrap > span:hover .dot {
  opacity: 1;
}

.mapMobile {
  display: none;
}

.mNav {
  display: none;
}

.content {
  min-height: calc(100vh - 300px);
}

.header img {
  -webkit-animation: spin 1s linear 0s;
  animation: spin 1s linear 0s;
  transform-origin: center center;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    opacity: 0;
    transform: rotate(0deg) scale(0.5);
  }
  to {
    opacity: 1;
    transform: rotate(360deg) scale(1);
  }
}
@media only screen and (max-width: 600px) {
  .headerNavWrap {
    height: 60px;
  }
  .headerInner h1 {
    font-size: 21px;
    color: #fff;
  }
  .headerNavWrap > span {
    font-size: 12px;
    min-width: 0px;
  }
}
