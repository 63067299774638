.wrap {
  padding-bottom: 100px;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.topHolder {
  height: 120px;
  background: #000;
}

.top {
  position: relative;
}

.top > img {
  width: 100%;
  min-height: 600px;
  object-fit: cover;
}

.topIn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
}

.topTitle {
  font-size: 32px;
  color: #fff;
  margin-bottom: 16px;
}

.topSub {
  font-size: 58px;
  color: #fff;
}

.hInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.hInfo img {
  margin-top: 48px;
  margin-bottom: 24px;
}

.iHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 46px;
  color: #fff;
  font-weight: bold;
}

.hLine {
  width: 60px;
  display: inline-block;
  height: 2px;
  background-color: #ddd;
  margin: 0 24px;
}

.iHeadEn {
  color: #eee;
  font-size: 24px;
  margin-top: 24px;
}

.content,
.topInfo {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.topInfo {
  flex-direction: column;
}

.topInfo p {
  margin-bottom: 18px;
}

.content {
  flex-direction: column;
  align-items: center;
}

.content .hInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  width: 100%;
}

.content .iHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 46px;
  color: #000;
  font-weight: bold;
}

.content .iHeadEn {
  color: #aaa;
  font-size: 24px;
  margin-top: 24px;
}

.topInfo {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
}

.content2 {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  margin-top: 48px;
}

.left {
  width: 45%;
  flex-shrink: 0;
  margin-right: 48px;
}

.l1 img {
  width: 100%;
}

.l2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.l21 {
  width: 56%;
}

.l22 {
  width: 40%;
}

.right {
  flex-grow: 1;
  color: #000;
}

.right h3 {
  font-size: 40px;
  margin-top: 8px;
  color: #000;
}

.right p,
.content2 p {
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 24px;
}

.pQ {
  color: rgba(0, 86, 225, 1);
  background: #fafcff;
  border-left: 10px solid #1e59d9;
  padding: 16px;
  font-size: 21px;
}

.itemIcon {
  display: inline-block;
  width: 120px;
  height: 120px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
}

.itemIcon img {
  width: 100%;
}

.item {
  text-align: center;
  margin-bottom: 64px;
}

.iTitle {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
}

.imgList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 36px;
}

.imgList img {
  width: 33%;
}

@media only screen and (max-width: 600px) {
  .topIn {
    padding-top: 60px;
  }
  .topTitle {
    font-size: 18px;
  }

  .topSub {
    font-size: 21px;
  }

  .hInfo {
    margin-bottom: 48px;
  }
  .hInfo img {
    margin-top: 24px;
    margin-bottom: 12px;
    width: 50px;
  }
  .iHead {
    font-size: 18px;
  }
  .iHeadEn {
    color: #fff;
    font-size: 18px;
    margin-top: 12px;
  }
  .topHolder {
    height: 60px;
    background: #000;
  }
  .top > img {
    height: 100%;
    width: auto;
  }
  .top {
    overflow-x: hidden;
  }
  .topInfo {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
  }

  .content .hInfo {
  }

  .content .iHead {
    font-size: 24px;
  }

  .content .iHeadEn {
    color: #333;
    font-size: 18px;
    margin-top: 12px;
  }
  .hLine {
    width: 40px;
  }
}
