.wrap {
  padding-bottom: 100px;
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.top {
  position: relative;
}

.top img {
  width: 100%;
  transform: scale(1.2);
  transform-origin: center center;
  opacity: 0.8;
  transition: all linear 400ms;
}

.topIn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
  transition: all linear 400ms;
  transition-delay: 400ms;
}

.topTitle {
  font-size: 32px;
  color: #fff;
  margin-bottom: 16px;
  transition: all linear 400ms;
  opacity: 0;
  transform: translateY(-200%);
}

.topSub {
  font-size: 58px;
  color: #fff;
  transition: all linear 400ms;
  opacity: 0;
  transform: translateY(200%);
}

.animation .topTitle {
  opacity: 1;
  transform: translateY(0);
}

.animation .topSub {
  opacity: 1;
  transform: translateY(0);
}

.animation .top img {
  transform: scale(1);
  opacity: 1;
}

.animation .top .topIn {
  background-color: rgba(0, 0, 0, 0.2);
}

.hInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.hInfo img {
  margin-top: 48px;
  margin-bottom: 24px;
}

.iHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 46px;
  color: #000;
  font-weight: bold;
}

.hLine {
  width: 60px;
  display: inline-block;
  height: 2px;
  background-color: #ddd;
  margin: 0 24px;
}

.iHeadEn {
  color: #aaa;
  font-size: 24px;
  margin-top: 24px;
}

.content {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.content2 {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  margin-top: 48px;
}

.left {
  width: 45%;
  flex-shrink: 0;
  margin-right: 48px;
}

.l1 img {
  width: 100%;
}

.l2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.l21 {
  width: 56%;
}

.l22 {
  width: 40%;
}

.right {
  flex-grow: 1;
  color: #000;
}

.right h3 {
  font-size: 40px;
  margin-top: 8px;
  color: #000;
}

.right p,
.content2 p {
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 24px;
}

.pQ {
  color: rgba(0, 86, 225, 1);
  background: #fafcff;
  border-left: 10px solid #1e59d9;
  padding: 16px;
  font-size: 21px;
}

.itemIcon {
  display: inline-block;
  width: 120px;
  height: 120px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
}

.itemIcon img {
  width: 100%;
}

.item {
  width: 30%;
  height: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.05);
  font-size: 34px;
  font-weight: 500;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 48px;
}

@media only screen and (max-width: 600px) {
  .topIn {
    padding-top: 60px;
  }
  .topTitle {
    font-size: 18px;
  }

  .topSub {
    font-size: 21px;
  }
  .hInfo img {
    margin-top: 24px;
    margin-bottom: 12px;
    width: 50px;
  }
  .iHead {
    font-size: 21px;
  }

  .hLine {
    width: 40px;
  }

  .iHeadEn {
    color: #aaa;
    font-size: 18px;
    margin-top: 12px;
  }
  .content {
    flex-direction: column;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
    margin-top: 18px;
  }

  .right h3 {
    font-size: 24px;
  }

  .right p,
  .content2 p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .pQ {
    padding: 8px;
    font-size: 12px;
  }

  .item {
    width: 100%;
    height: 180px;
    font-size: 18px;
    font-weight: 500;
    justify-content: flex-start;
  }

  .list {
    flex-direction: column;
    margin-top: 18px;
    margin-bottom: 12px;
  }
}
