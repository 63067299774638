.wrap {
}

.content {
  width: 90%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.topHolder {
  height: 120px;
  background: #000;
}

.top {
  background: url("../img/c/i1.png") center 120px no-repeat;
  background-size: cover;
}

.topIn {
  margin-top: 0px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
}

.topTitle {
  font-size: 32px;
  color: #fff;
  margin-bottom: 16px;
}

.topSub {
  font-size: 58px;
  color: #fff;
}

.hInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.hInfo img {
  margin-top: 48px;
  margin-bottom: 24px;
}

.iHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 46px;
  color: #fff;
  font-weight: bold;
}

.hLine {
  width: 60px;
  display: inline-block;
  height: 2px;
  background-color: #ddd;
  margin: 0 24px;
}

.iHeadEn {
  color: #eee;
  font-size: 24px;
  margin-top: 24px;
}

.topInfo {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
}

.content,
.topInfo {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.s1ContentWrap {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

.c1Item {
  display: flex;
  flex-direction: row;
  line-height: 60px;
  border-radius: 3px;
  margin-bottom: 50px;
  padding: 10px 16px;
  width: 100%;
  max-width: 900px;
  margin: 24px auto;
  border-radius: 10px;
  background: #fff;
}

.c1label {
  font-size: 20px;
  min-width: 130px;
  flex-shrink: 0;
  margin-right: 10px;
  font-weight: 500;
  text-align: left;
}

.c1Value {
  flex-grow: 1;
  text-align: left;
}

.c1Value select {
  width: 200px;
  font-size: 22px;
  border: none;
  outline: none;
  color: #444;
}
.c1Value input,
.c1Value textarea {
  width: 90%;
  min-height: 60px;
  padding-right: 16px;
  border: none;
  outline: none;
  font-size: 22px;
  color: #444;
  background: none;
}

.c1Value textarea {
  min-height: 180px;
  padding-top: 16px;
}

.c1Button {
  margin-top: 20px;
  text-align: left;
  display: block;
  margin: 0 auto;
}

.c1Button span {
  display: inline-block;
  width: 270px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #fff;
  margin: 20px auto;
  background: rgba(14, 142, 253, 1);
  font-weight: 500;
  border-radius: 30px;
  transition: all linear 200ms;
}

.c1Button .disable {
  opacity: 0.5 !important;
  cursor: not-allowed;
  color: #999 !important;
}

.c1Button span:hover {
  opacity: 1;
  color: #fff;
}

.footer {
  height: 180px;
  line-height: 60px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-size: cover;
}
.footerInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footB {
  color: #fff;
}

.footerInfo {
  flex-grow: 1;
}

.footB {
  flex-shrink: 0;
  line-height: 48px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  width: 80%;
  max-width: 1400px;
}

@media only screen and (max-width: 600px) {
  .top {
    background: url("../img/c/i1.png") center 60px no-repeat;
    background-size: cover;
  }
  .topHolder {
    height: 60px;
    background: #000;
  }
  .topTitle {
    font-size: 18px;
  }

  .topSub {
    font-size: 21px;
  }
  .hInfo img {
    margin-top: 24px;
    margin-bottom: 12px;
    width: 50px;
    margin-bottom: 48px;
  }
  .iHead {
    font-size: 24px;
  }
  .iHeadEn {
    color: #fff;
    font-size: 18px;
    margin-top: 12px;
  }
  .topHolder {
    height: 60px;
    background: #000;
  }
  .top > img {
    height: 100%;
    width: auto;
  }
  .top {
    overflow-x: hidden;
  }
  .topInfo {
    color: #fff;
    font-size: 18px;
    line-height: 28px;
  }

  .content .hInfo {
  }

  .content .iHead {
    font-size: 24px;
  }

  .content .iHeadEn {
    color: #333;
    font-size: 18px;
    margin-top: 12px;
  }

  .c1label {
    font-size: 14px;
    min-width: 80px;
  }
  .c1Value {
    font-size: 14px;
  }
  .c1Value select {
    width: 150px;
    font-size: 14px;
    border: none;
    outline: none;
  }
  .c1Value input,
  .c1Value textarea {
    width: 90%;
    font-size: 14px;
    color: #444;
    background: none;
  }
  .hLine {
    width: 40px;
  }
}
