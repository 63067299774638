.footer {
  height: 120px;
  line-height: 60px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  border-top: 1px solid #000;
  background: url("../img/footer.png") center center no-repeat;
  background-size: cover;
}
.footerInner {
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footB {
  color: #fff;
}

.footerInfo {
  flex-grow: 1;
}

.footB {
  flex-shrink: 0;
  line-height: 48px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  width: 80%;
  max-width: 1400px;
}

@media only screen and (max-width: 600px) {
}
